import React from "react";
import Layout from "../../other/layout";
import SEO from "../../other/seo";
import { LINK_DISTRIBUTION } from "../../project-config";

export default function Contact() {
  return (
    <Layout>
      <SEO title="Contact" />
      <div className="row contact-page-wrapper">
        <h1 class="title-text">Contact</h1>
        <p>
          We would like to hear from you! At DFS, we are always looking for ways
          to improve our service, product and marketing offerings to our valued
          Dealers. <br></br>
          <br></br> <strong>Email:</strong>&nbsp;
          <a href="mailto:printing@dfsbusiness.com">
            printing@dfsbusiness.com
          </a>{" "}
          <br></br> <br></br>
          <strong>Phone: </strong>800.490.6327 ( 8am to 5:30pm EST ) <br></br>
          <br></br>
          <strong>Fax:</strong> 800.255.6327 <br></br>
          <br></br>
          <strong>Mail:</strong>
          <br></br> DFS <br></br> 330 Cranston Crescent <br></br> Midland,
          Ontario, L4R 4V9
        </p>
        <p>
          You may also use our{" "}
          <a
            href={`https://www.dfsonline.ca/shop/store/20090722001/assets/pdf/Dealer_English_Standard_Order_Form_EDITABLE_VERSION.pdf`}
            style={{ textDecoration: "underline", color: "#000" }}
          >
            Standard Order Form (PDF)
          </a>
          &nbsp; (PDF must be emailed or faxed).
        </p>
      </div>
    </Layout>
  );
}
